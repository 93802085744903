import {Link} from 'react-router-dom';
import './BottomBar.css'

export const BottomBar = () => {
    const {pathname} = window.location;
    const user = JSON.parse(localStorage.getItem('user'));
    return (
        user?.role === "owner" ? <div className="bottom-bar">
            <Link to={'/dashboard'} className={`icon ${pathname === '/dashboard' && 'selected-icon'}`}>
                <i className={`fas fa-home ${pathname === '/dashboard' && 'selected-icon'}`}/>
            </Link>
            <Link className={`icon ${pathname === '/suppliers' && 'selected-icon'}`} to={'/suppliers'}>
                <i className={`fas fa-truck ${pathname === '/suppliers' && 'selected-icon'}`}/>
            </Link>
            <Link to={'/products'} className={`icon ${pathname === '/products' && 'selected-icon'}`}>
                <i className={`fas fa-cart-shopping ${pathname === '/products' && 'selected-icon'}`}/>
            </Link>
            <Link className={`icon ${pathname === '/orders' && 'selected-icon'}`} to='/orders'>
                <i className={`fas fa-clipboard-list ${pathname === '/orders' && 'selected-icon'}`}/>
            </Link>

        </div> :

        <div className="bottom-bar-compact">
            <Link to={'/'} className={`icon ${pathname === '/' && 'selected-icon'}`}>
                <i className={`fas fa-truck ${pathname === '/' && 'selected-icon'}`}/>
            </Link>
            <Link to={'/products'} className={`icon ${pathname === '/products' && 'selected-icon'}`}>
                <i className={`fas fa-cart-shopping ${pathname === '/products' && 'selected-icon'}`}/>
            </Link>
        </div>

    )
};