import './TotalUnpaidBills.css'
import {currentMonth, currentQuarter, currentYear, euro, totalUnpaidBillsText} from "../../Constants/translations";
import {useGetUnpaidBillsQuery} from "../../redux/apiServices/merchantApi";
import {formatCashValue} from "../../util";

export const TotalUnpaidBills = () => {
    const language = localStorage.getItem('language');
    const {data:unpaidBills, isLoading} = useGetUnpaidBillsQuery(localStorage.getItem('merchant'));

    return (
        <div className="total-unpaid-bills-container">
            <div className="main-title">
                <span>{totalUnpaidBillsText[language]}</span>
            </div>
            <div className="total-unpaid-bills">
                <div className="amount-component">
                    <div className="unpaid-bill-title">
                        {currentMonth[language]}
                    </div>
                    <div className="amount">
                        {euro}
                        {
                            isLoading ? '...' : formatCashValue(unpaidBills?.monthly?._sum?.billAmount || 0)
                        }
                    </div>
                </div>

                <div className="amount-component">
                    <div className="unpaid-bill-title">
                        {currentQuarter[language]}
                    </div>
                    <div className="amount">
                        {euro}{
                        isLoading ? '...' : formatCashValue(unpaidBills?.quarterly?._sum?.billAmount || 0)
                    }
                    </div>
                </div>

                <div className="amount-component">
                    <div className="unpaid-bill-title">
                        {currentYear[language]}
                    </div>
                    <div className="amount">
                        {euro}{
                        isLoading ? '...' : formatCashValue(unpaidBills?.yearly?._sum?.billAmount || 0)
                    }
                    </div>
                </div>
            </div>
        </div>
    );
}