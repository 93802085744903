import './TopBar.css'
import {dashboard, help, logoutConfirmation, products, profile, signOut, suppliers} from "../../Constants/translations";
import {useEffect, useRef, useState} from "react";
import frenchFlag from "../../Assets/france.png";
import {Link, useNavigate} from 'react-router-dom';

export const TopBar = ({heading}) => {
    const language = localStorage.getItem('language');
    const [menu, setMenu] = useState(false);
    const [confirmSignOut, setConfirmSignOut] = useState(false);
    const menuRef = useRef();
    const navigate = useNavigate();

    // Function to handle click away
    const handleClickAway = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenu(false);
        }
    };

    // Add event listener for click events when the menu is open
    useEffect(() => {
        if (menu) {
            document.addEventListener('click', handleClickAway);
        } else {
            document.removeEventListener('click', handleClickAway);
        }

        // Clean up function to remove event listener
        return () => {
            document.removeEventListener('click', handleClickAway);
        };
    }, [menu]);

    const handleSignOut = () => {
        setMenu(false);
            localStorage.removeItem('merchant');
            localStorage.removeItem('user');
            navigate('/login');
    };


    return (
        <div>
        <div className="top-bar">
            <div></div>
            <div className="title">
                {heading === "dashboard" ? dashboard[language] : heading === "suppliers" ? suppliers[language] : heading === "products" ? products[language] : heading}
            </div>
            <div className="button-div">
                <button className="btn" onClick={
                    (e) => {
                        e.stopPropagation();
                        setMenu(!menu);
                    }
                }>
                    <i className="fa-solid fa-bars"></i>
                </button>


            </div>
        </div>
            {menu && (
                <div className="menu" ref={menuRef}>
                    <Link className="menu-item" to={'/profile'}>
                        {profile[language]}
                    </Link>
                    <Link className="menu-item" to={'/help'}>
                        {help[language]}
                    </Link>
                    <button className="menu-item" onClick={
                        () => {
                            setConfirmSignOut(true)
                        }
                    }>
                        {signOut[language]}
                    </button>
                    {/* <button className="menu-item" onClick={
                        () => {
                            localStorage.setItem('language', language === '1' ? 0 : 1);
                            window.location.reload();
                            setMenu(false)
                        }
                    }>
                        French/English
                    </button> */}
                </div>
            )
            }
            {
                confirmSignOut && (
                    <div className="confirm-sign-out">
                        <div>
                           {logoutConfirmation[language]} 
                        </div>
                        <div className="confirm-buttons">
                            <button className="yes" onClick={handleSignOut}>
                                {'Oui'}
                            </button>
                            <button className="no" onClick={
                                () => {
                                    setConfirmSignOut(false)
                                }
                            }>
                                {'Non'}
                            </button>
                        </div>
                    </div>
                )
            }
        </div>
    );
}