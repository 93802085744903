import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Login } from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import Suppliers from "./pages/Suppliers/Suppliers";
import Products from "./pages/Products/Products";
import Orders from "./pages/Orders/Orders";
import { Provider } from "react-redux";
import Profile from "./pages/Profile/Profile";
import Help from "./pages/Help/Help";
import store from "./redux/store";
import AuthLayout from './Layouts/AuthLayout';

function App() {
    const merchant = localStorage.getItem('merchant');
    const user = JSON.parse(localStorage.getItem('user'));

    return (
        <Provider store={store}>
            <AuthLayout>
                <Router>
                    <Routes>
                        <Route path="/help" element={Help.getLayout(<Help />)} />
                        <Route path="/profile" element={Profile.getLayout(<Profile />)} />
                        <Route path="/dashboard" element={Dashboard.getLayout(<Dashboard />)} />
                        <Route path="/suppliers" element={Suppliers.getLayout(<Suppliers />)} />
                        <Route path="/products" element={Products.getLayout(<Products />)} />
                        <Route path="/orders" element={Orders.getLayout(<Orders />)} />
                        <Route path="/" element={(merchant && user) ? Suppliers.getLayout(<Suppliers />) : <Login />} />
                        <Route path="/products" element={Products.getLayout(<Products />)} />
                        <Route path="/login" element={<Login />} />
                    </Routes>
                </Router>
            </AuthLayout>
        </Provider>
    );
}

export default App;