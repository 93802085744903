import React, { useEffect } from 'react'

const AuthLayout = ({ children }) => {
    const merchant = localStorage.getItem('merchant');
    const user = JSON.parse(localStorage.getItem('user'));
    
    useEffect(() => {
        if (user && merchant) {
            if (window.location.pathname === '/login' || window.location.pathname === '/') {
                if (user.role === 'owner') {
                    window.location.href = "/dashboard";
                } else {
                    window.location.href = "/";
                }
            }
        } else {
            if (window.location.pathname !== '/login') {
                window.location.href = "/login";
            }
        }
    }, [user, merchant]);

    return (
        <>
            {children}
        </>
    )
}

export default AuthLayout